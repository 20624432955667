import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import GlobalLoader from "../../GlobalLoader";
import NoResultsFound from "../../NoResultsFound";

function RemoveIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#0079C1"/>
    <rect x="10" y="19" width="20" height="3" rx="1.5" fill="white"/>
    </svg>
  )
}

function AddIcon() {
  return (
    <svg width="40" height="40" viewBox="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.804199" y="0.140869" width="40" height="40" rx="8" fill="#F7F7FF"/>
    <path d="M21.5542 11.7409C20.9191 11.7409 20.4042 12.2557 20.4042 12.8909V28.8909C20.4042 29.526 20.9191 30.0409 21.5542 30.0409C22.1893 30.0409 22.7042 29.526 22.7042 28.8909V12.8909C22.7042 12.2557 22.1893 11.7409 21.5542 11.7409Z" fill="#6F6C90" stroke="#6F6C90" stroke-width="0.3" stroke-linecap="round"/>
    <path d="M13.5542 19.7409C12.9191 19.7409 12.4042 20.2557 12.4042 20.8909C12.4042 21.526 12.9191 22.0409 13.5542 22.0409H29.5542C30.1893 22.0409 30.7042 21.526 30.7042 20.8909C30.7042 20.2557 30.1893 19.7409 29.5542 19.7409H13.5542Z" fill="#6F6C90" stroke="#6F6C90" stroke-width="0.3" stroke-linecap="round"/>
    </svg>
  )
}

function SingleFAQsInformation(props) {
    const [expanded, setExpanded] = useState(1);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : null);
    };

    return (
      props?.loading ?
        <GlobalLoader height="70vh" />
        :
        <Box sx={{px: {xs: 0, md: 8}}}>
            {props?.faqs?.length > 0 ?
                <>
                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontWeight: 700,
                        fontSize: {xs: "18px", md: "24px"},
                        lineHeight: "35px",
                        color: "#170F49",
                        mt: "25px",
                        mb: 4,
                    }}>
                        Frequently Asked Question about our company
                    </Typography>

                    <Grid container spacing={2}>
                        {props?.faqs?.map((faq) => (
                            <Grid key={faq.id} item xs={12} md={6}>
                                <Accordion
                                    expanded={expanded === faq.id}
                                    onChange={handleAccordionChange(faq.id)}
                                    sx={{
                                        boxShadow: 3,
                                        border: "1px solid #E6E9ED",
                                        transition: 'box-shadow 0.3s ease',
                                        my: 1,
                                        padding: "24px",
                                    }}
                                    style={{borderRadius: "16px"}}
                                >
                                    <AccordionSummary 
                                        expandIcon={expanded === faq.id ? <RemoveIcon fontSize="large" /> : <AddIcon />}
                                        sx={{
                                            backgroundColor: "#FFFFFF",
                                        }}
                                    >
                                    <Typography sx={{color: "#424447", fontFamily: "Epilogue", fontWeight: 700, fontSize: "16px", lineHeight: "24px"}} >
                                        {faq.question}
                                    </Typography>
                                    </AccordionSummary>
                                    {faq.answer && (
                                    <AccordionDetails sx={{display: "flex", paddingLeft: "0px"}}>
                                        <Typography sx={{color: "#424447", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>{faq.answer}</Typography>
                                    </AccordionDetails>
                                    )}
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                </>
                :
                <NoResultsFound />
            }
        </Box>
    )
}

export default SingleFAQsInformation