import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import JobDetailsEmployeSidebar from "../components/Job/JobDetails/JobDetailsEmployeSidebar";
import JobInformations from "../components/Job/JobDetails/JobInformations";
import { useParams } from "react-router-dom";
import {
  getJobDetails,
  getSimilarJobs,
  postActivityLogs,
} from "../../fetchAPI";
import CandidateContextProvider from "../context/CandidateContext";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import SocialsComponent from "../components/Job/JobDetails/SocialsComponent";
import SimilarJobsComponent from "../components/Job/JobDetails/SimilarJobsComponent";
import JobSearchContextProvider from "../context/JobSearchContext";
import PageNotFound from "./PageNotFound";
import GlobalLoader from "../components/GlobalLoader";
import { fetchIpAddress } from "../helpers/helperFunctions";

function JobDetails() {
  const { job_id } = useParams();

  const [jobData, setJobData] = useState("");
  const [jobDataLoading, setJobDataLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [similarJobData, setSimilarJobData] = useState(null);
  const pageTitle = "job-details";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setJobDataLoading(true);
    getJobDetails(job_id)
      .then((response) => {
        // Check if the response indicates success
        if (response && response.success) {
          setJobData(response.response.data);
        } else {
          console.error("Unexpected response format:", response);
          if (response.errors[0].code === 404) {
            setNoDataFound(true);
          }
          // Handle the error (e.g., show an error message to the user)
        }
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        // Handle the error (e.g., show an error message to the user)
      })
      .finally(() => setJobDataLoading(false));
  }, [job_id]);

  useEffect(() => {
    setJobDataLoading(true);
    getSimilarJobs(job_id)
      .then((response) => {
        // Check if the response indicates success
        if (response && response.success) {
          setSimilarJobData(response.response.data);
        } else {
          console.error("Unexpected response format:", response);
          // Handle the error (e.g., show an error message to the user)
        }
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        // Handle the error (e.g., show an error message to the user)
      })
      .finally(() => setJobDataLoading(false));
  }, [job_id]);

  useEffect(() => {
    const logActivity = async () => {
      const ipAddress = await fetchIpAddress();
      const params = {
        page_title: pageTitle,
        action_type: "view",
        page_url: currentPageURL,
        ip: ipAddress,
        job_post_id: job_id,
        platform: "web",
        user_agent: userAgent,
      };
      await postActivityLogs(params);
    };

    logActivity();
  }, []);

  if (noDataFound) {
    return <PageNotFound />;
  }

  return (
    <>
      {jobDataLoading ? (
        <GlobalLoader height="85vh" />
      ) : (
        <Box
          sx={{
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "228px",
              backgroundColor: "#0275D8",
            }}
          />

          <Box
            sx={{
              position: "relative",
              top: "-325px",
              display: "flex",
              marginTop: "150px",
              marginLeft: "30px",
              marginRight: "30px",
              zIndex: 2,
              "@media (max-width: 600px)": {
                marginLeft: 1,
                marginRight: 1,
              },
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                "@media (max-width: 600px)": {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            >
              <Grid container spacing={6}>
                <Grid item md={3} xs={12} order={{ xs: 2, md: 1 }}>
                  <Stack spacing={2}>
                    {/* Employer Card */}
                    {jobData?.is_cxo ? (
                      <Card
                        sx={{
                          display: "flex",
                          // height: '100%',
                          width: "100%",
                          borderRadius: "10px",
                          justifyContent: "center",
                          margin: "0px",
                          padding: 2,
                          border: "1px solid #C4C4C4",
                        }}
                      >
                        <img src="/images/cxo/blurred-sidebar.png" />
                      </Card>
                    ) : (
                      <>
                        <Card
                          sx={{
                            display: "flex",
                            // height: '100%',
                            width: "100%",
                            borderRadius: "10px",
                            justifyContent: "center",
                            margin: "0px",
                            padding: 2,
                            border: "1px solid #C4C4C4",
                          }}
                        >
                          <CandidateContextProvider>
                            <JobDetailsEmployeSidebar
                              jobData={jobData}
                              jobDataLoading={jobDataLoading}
                            />
                          </CandidateContextProvider>
                        </Card>

                        {/* Social Component */}
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // height: '100%',
                            width: "100%",
                            borderRadius: "10px",
                            justifyContent: "center",
                            margin: "10px",
                            padding: 2,
                            border: "1px solid #C4C4C4",
                            "@media (max-width: 600px)": {
                              margin: "0px",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{
                              // marginTop: 2,
                              margin: "12px",
                              color: "#424447",
                              fontWeight: 700,
                              fontSize: "18px",
                              "@media (max-width: 600px)": {
                                fontSize: "16px",
                              },
                            }}
                          >
                            Share This Job
                          </Typography>

                          <Divider />

                          <SocialsComponent
                            socialLinks={jobData?.employer?.social_networks}
                          />
                        </Card>
                      </>
                    )}

                    {similarJobData &&
                    similarJobData?.similar_jobs?.length > 0 ? (
                      <React.Fragment>
                        {/* Similar Jobs Component */}
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // height: '100%',
                            width: "100%",
                            borderRadius: "10px",
                            // justifyContent: 'center',
                            margin: "10px",
                            padding: 2,
                            border: "1px solid #C4C4C4",
                            "@media (max-width: 600px)": {
                              margin: "0px",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{
                              marginTop: 2,
                              margin: "12px",
                              color: "#1D1E1B",
                              fontWeight: 700,
                              fontSize: "20px",
                              "@media (max-width: 600px)": {
                                fontSize: "16px",
                              },
                            }}
                          >
                            Similar Jobs{" "}
                            <span style={{ color: "#0275D8" }}>
                              ({similarJobData?.total_similar_jobs})
                            </span>
                          </Typography>

                          <Divider />

                          <CandidateContextProvider>
                            <SimilarJobsComponent
                              data={similarJobData?.similar_jobs}
                            />
                          </CandidateContextProvider>
                        </Card>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Stack>
                  {/* -- bottom warning message for mobile device start -- */}
                  <Box
                    component="div"
                    sx={{
                      pt: "30px",
                      pb: "20px",
                      px: "5px",
                      textAlign: "justify",
                      color: "#000",
                      fontSize: "15px",
                      lineHeight: "26px",
                      fontWeight: "500",
                      display: {
                        md: "none",
                      },
                    }}
                  >
                    <Typography component="p">
                      প্রতারকদের থেকে সাবধান!
                    </Typography>
                    <Typography component="p" sx={{ py: "20px" }}>
                      <Link href="https://www.atbjobs.com" target="_blank">
                        atbjobs.com
                      </Link>{" "}
                      কোনো অর্থের বিনিময়ে চাকরি পাওয়া বা ইন্টারভিউয়ের
                      নিশ্চয়তা প্রদান করে না। প্রতারণাকারীরা নিবন্ধন ফি,
                      ফেরতযোগ্য ফি, বা এ ধরনের অজুহাতে অর্থ দাবি করতে পারে—এই
                      ধরনের প্রস্তাব থেকে সতর্ক থাকুন।
                    </Typography>
                    <Typography component="p">
                      আপনি যদি এ ধরনের কোনো সন্দেহজনক ইমেইল বা বার্তা পান, তাহলে
                      আমাদের সাথে দ্রুত যোগাযোগ করুন।
                    </Typography>

                    <Typography component="p" sx={{ pt: "20px" }}>
                      ফোন:{" "}
                      <Box component="span" sx={{ color: "#0275d8" }}>
                        +8801400492818
                      </Box>
                    </Typography>
                    <Typography component="p">
                      ইমেইল:{" "}
                      <Box component="span" sx={{ color: "#0275d8" }}>
                        contact@atb-jobs.com
                      </Box>
                    </Typography>
                  </Box>
                  {/* -- bottom warning message mobile device end -- */}
                </Grid>
                
                <Grid item md={9} xs={12} order={{ xs: 1, md: 2 }}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "10px",
                      margin: "0px",
                      border: "1px solid #C4C4C4",
                    }}
                  >
                    {/* Job Information Content */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        maxHeight: isExpanded ? "none" : "850px",
                        transition: "max-height 0.3s ease",
                      }}
                    >
                      <CandidateContextProvider>
                        <JobInformations
                          jobData={jobData}
                          jobDataLoading={jobDataLoading}
                        />
                      </CandidateContextProvider>
                    </Box>

                    {/* "See More" Button (Always visible at the bottom left) */}
                    <Typography
                      variant="body1"
                      sx={{
                        alignSelf: "flex-start",
                        mt: 1,
                        padding: 3,
                        marginLeft: 2,
                        position: "relative",
                        zIndex: 1,
                        color: "#DB1616",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={toggleExpansion}
                    >
                      {isExpanded ? "Show less" : "Show more"}
                    </Typography>
                  </Card>

                  {/* -- bottom warning message for pc start -- */}
                  <Box
                    component="div"
                    sx={{
                      pt: "30px",
                      pb: "20px",
                      px: "5px",
                      textAlign: "justify",
                      color: "#000",
                      fontSize: "15px",
                      lineHeight: "26px",
                      fontWeight: "500",
                      display: {
                        xs: "none",
                        md: "block",
                      },
                    }}
                  >
                    <Typography component="p">
                      প্রতারকদের থেকে সাবধান!
                    </Typography>
                    <Typography component="p" sx={{ py: "20px" }}>
                      <Link href="https://www.atbjobs.com" target="_blank">
                        atbjobs.com
                      </Link>{" "}
                      কোনো অর্থের বিনিময়ে চাকরি পাওয়া বা ইন্টারভিউয়ের
                      নিশ্চয়তা প্রদান করে না। প্রতারণাকারীরা নিবন্ধন ফি,
                      ফেরতযোগ্য ফি, বা এ ধরনের অজুহাতে অর্থ দাবি করতে পারে—এই
                      ধরনের প্রস্তাব থেকে সতর্ক থাকুন।
                    </Typography>
                    <Typography component="p">
                      আপনি যদি এ ধরনের কোনো সন্দেহজনক ইমেইল বা বার্তা পান, তাহলে
                      আমাদের সাথে দ্রুত যোগাযোগ করুন।
                    </Typography>

                    <Typography component="p" sx={{ pt: "20px" }}>
                      ফোন:{" "}
                      <Box component="span" sx={{ color: "#0275d8" }}>
                        +8801400492818
                      </Box>
                    </Typography>
                    <Typography component="p">
                      ইমেইল:{" "}
                      <Box component="span" sx={{ color: "#0275d8" }}>
                        contact@atb-jobs.com
                      </Box>
                    </Typography>
                  </Box>
                  {/* -- bottom warning message pc end -- */}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
}

export default JobDetails;
