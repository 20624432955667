import { Avatar, Box, Divider, Grid, Paper, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import ReactHtmlParser from 'react-html-parser'
import { CompanyInformationCard } from "./SingleCompanyInformation";

function ProfileNavbar(props) {
    return (
        <React.Fragment>
            {/* Desktop View */}
            <Paper
                sx={{
                    padding: 2,
                    borderRadius: '5px',
                    marginTop: 7,
                    display: { md: 'block', xs: 'none' }
                }}
            >
                <Stack direction={'row'} spacing={2} justifyContent={props.content[0] && props.jobList == false ? 'space-around' : 'flex-start'}>
                    <Link
                        className={`m-3 hover-underline-animation text-dark nav-link`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to='about_box'
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            Company Overview
                        </Typography>
                    </Link>

                    <Link
                        className={`m-3 hover-underline-animation text-dark nav-link`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to='meet_the_team'
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            Meet The Team
                        </Typography>
                    </Link>

                    <Link
                        className={`m-3 hover-underline-animation text-dark nav-link`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to='mission_vision'
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            What We Do?
                        </Typography>
                    </Link>

                    <Link
                        className={`m-3 hover-underline-animation text-dark nav-link`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to='how_we_do'
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            How We Do?
                        </Typography>
                    </Link>

                    <Link
                        className={`m-3 hover-underline-animation text-dark nav-link`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        to='our_culture'
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            Our Culture
                        </Typography>
                    </Link>
                </Stack>
            </Paper>

            {/* Mobile View */}
            <ScrollableTabsButtonAuto info={props.info} content={props.content} />
        </React.Fragment>
    )
}

function CompanyOverviewSection(props) {    
    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
            id='about_box'
            sx={{
                padding: 4,
                borderRadius: '5px',
            }}>
            <Typography
                variant="h5"
                sx={{
                    fontFamily: "Epilogue",
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#1D1E1B',
                    lineHeight: "24px",
                }}>
                Company Overview
            </Typography>
            <Box mt={2} />
            <Divider />
            <Box mt={2} />

            {
                props.loading ?
                    <Box>
                        <Skeleton variant="text" height={30} width={'100%'} />
                        <Box mt={2} />
                        <Divider />
                        <Box mt={2} />
                        <Skeleton variant="square" height={300} width={'100%'} />
                    </Box>
                    :
                    <Box onCopy={handleCopy} onCut={handleCopy}>
                        <Typography
                            variant="h3"
                            align='center'
                            sx={{
                                fontFamily: "Epilogue",
                                fontWeight: 600,
                                color: "#1D1E1B",
                                fontSize: '16px',
                                lineHeight: "20px",
                                whiteSpace: "pre-line",
                            }}>
                            {ReactHtmlParser(props.content[0])}
                        </Typography>
                        <Box mt={2} />
                        <Divider />
                        <Box mt={2} />
                        <img
                            src={props.imageContent[0] ? props.imageContent[0] : '/v2/images/banner-placeholder.png'}
                            width={props.imageContent ? '100%' : '60%'}
                            height={props.imageContent ? '100%' : '100%'}
                            alt="Company Overview Image"
                            style={{ display: 'block', margin: 'auto' }}
                        />
                    </Box>
            }
        </Paper>
    )
}

function MeetTheTeamSection(props) {

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
            id='meet_the_team'
            sx={{
                padding: 4,
                borderRadius: '5px',
            }}>
            <Typography
                variant="h5"
                sx={{
                    fontFamily: "Epilogue",
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#1D1E1B',
                    lineHeight: "24px",
                }}>
                Meet The Team
            </Typography>
            <Box mt={2} />
            <Divider />
            <Box mt={2} />
            <Stack spacing={2}>
                {
                    props.loading ?
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={'center'}>
                                        <Skeleton variant="circular" width={80} height={80} />
                                        <Stack spacing={1}>
                                            <Skeleton variant="text" width={200} />
                                            <Skeleton variant="text" width={200} />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={'center'}>
                                        <Skeleton variant="circular" width={80} height={80} />
                                        <Stack spacing={1}>
                                            <Skeleton variant="text" width={200} />
                                            <Skeleton variant="text" width={200} />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        :
                        <Grid container spacing={2}>
                            {
                                props.team && props.team?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item lg={6} xs={12}>
                                                <Stack spacing={2} direction="row" sx={{alignItems: {xs: "flex-start", sm: "center"}}}>
                                                    <Box
                                                        sx={{
                                                            border: '4px solid #e0e0e0',
                                                            borderRadius: '50%',
                                                            padding: 1
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt="User Profile Picture"
                                                            src={item.s3_url ? item.s3_url : '/v2/images/user-placeholder.png'}
                                                            sx={{
                                                                height: {xs: "70px", sm: "150px", xl: "180px"},
                                                                width: {xs: "70px", sm: "150px", xl: "180px"},
                                                            }}
                                                        />
                                                    </Box>
                                                    <Stack spacing={1} onCopy={handleCopy} onCut={handleCopy} sx={{paddingTop: {xs: "16px", sm: "0px"}}}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontFamily: "Epilogue",
                                                                fontWeight: 700,
                                                                color: "#1D1E1B",
                                                                fontSize: '18px',
                                                                lineHeight: "20px",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontFamily: "Epilogue",
                                                                fontWeight: 700,
                                                                color: "#0275D8",
                                                                fontSize: '16px',
                                                                lineHeight: "20px",
                                                            }}
                                                        >
                                                            {item.designation}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontFamily: "Epilogue",
                                                                fontWeight: 700,
                                                                color: "#72737C",
                                                                fontSize: '14px',
                                                                lineHeight: "20px",
                                                            }}
                                                        >
                                                            {item.description}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Grid>
                }
            </Stack>
        </Paper>
    )
}

function OrganogramSection(props) {
    return (
        <Paper
            id='organogram'
            sx={{
                padding: 4,
                borderRadius: '5px',
            }}>
            <Typography
                variant="h5"
                sx={{
                    fontFamily: "Epilogue",
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#1D1E1B',
                    lineHeight: "24px",
                }}>
                Organogram Structure
            </Typography>
            <Box mt={2} />
            <Divider />
            <Box mt={2} />

            <img
                src={props.imageContent ? props.imageContent : '/v2/images/banner-placeholder.png'}
                width={props.imageContent ? '100%' : '60%'}
                height={props.imageContent ? '100%' : '100%'}
                alt="Organogram Structure"
                style={{ display: 'block', margin: 'auto' }}
            />
        </Paper>
    )
}

function WhatWeDo(props) {

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
        id='what_we_do'
        sx={{
            padding: 4,
            borderRadius: '5px',
        }}>
            <Stack spacing={4} onCopy={handleCopy} onCut={handleCopy} sx={{ padding: { xs: 1, md: 5 } }}>
                <Box>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: "Epilogue",
                            fontWeight: 700,
                            fontSize: '18px',
                            color: '#1D1E1B',
                            lineHeight: "24px",
                        }}>
                        What We Do?
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            fontSize: '14px',
                            color: '#0275D8'
                        }}>
                        (Scope of work)
                    </Typography>
                </Box>

                <Divider />

                <Box sx={{my: 2}}>
                    {
                    props.loading ?
                        <Skeleton variant="rounded" height={300} width={'100%'} />
                        :
                        <img
                            src={props.imageContent[1] ? props.imageContent[1] : '/v2/images/banner-placeholder.png'}
                            width={props.imageContent[1] ? '100%' : '60%'}
                            height={props.imageContent[1] ? '100%' : '100%'}
                            alt="What We Do Image"
                            style={{ display: 'block', margin: 'auto' }}
                        />

                    }
                </Box>

                {
                    props.loading ?
                        <Box>
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" height={50} width={'100%'} />
                        </Box>
                        :
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#72737C',
                                whiteSpace: "pre-line",
                            }}>
                            {ReactHtmlParser(props.content[1])}
                        </Typography>
                }

            </Stack>
    </Paper>
    )
}

function WhyWeDo(props) {
    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
        id='why_we_do'
        sx={{
            padding: 4,
            borderRadius: '5px',
        }}>
            <Stack spacing={4} onCopy={handleCopy} onCut={handleCopy} sx={{ padding: { xs: 1, md: 5 } }}>
                <Box>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: "Epilogue",
                            fontWeight: 700,
                            fontSize: '18px',
                            color: '#1D1E1B',
                            lineHeight: "24px",
                        }}>
                        Why We do?
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            fontSize: '14px',
                            color: '#0275D8'
                        }}>
                        (Vision)
                    </Typography>
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Divider />
                </Box>

                <Box sx={{my: 3}}>
                    {
                    props.loading ?
                        <Skeleton variant="rounded" height={300} width={'100%'} />
                        :
                        <img
                            src={props.imageContent[2] ? props.imageContent[2] : '/v2/images/banner-placeholder.png'}
                            width={props.imageContent[2] ? '100%' : '60%'}
                            height={props.imageContent[2] ? '100%' : '100%'}
                            alt="Why We Do Image"
                            style={{ display: 'block', margin: 'auto' }}
                        />
                    }
                </Box>

                {
                    props.loading ?
                        <Box>
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" width={'100%'} />
                            <Skeleton variant="text" height={50} width={'100%'} />
                        </Box>
                        :
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#72737C',
                                whiteSpace: "pre-line",
                            }}>
                            {ReactHtmlParser(props.content[2])}
                        </Typography>
                }
            </Stack>
    </Paper>
    )
}

function MissionAndVisionSection(props) {

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
            id='mission_vision'
            sx={{
                display: {xs: "none", md: "block"},
                padding: 4,
                borderRadius: '5px',
            }}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    {
                        props.loading ?
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                            :
                            <img
                                src={props.imageContent[1] ? props.imageContent[1] : '/v2/images/banner-placeholder.png'}
                                width={props.imageContent[1] ? '100%' : '60%'}
                                height={props.imageContent[1] ? '100%' : '100%'}
                                alt="What We Do Image"
                                style={{ display: 'block', margin: 'auto' }}
                            />

                    }
                </Grid>
                <Grid item md={6} xs={12}>
                    <Stack spacing={4} onCopy={handleCopy} onCut={handleCopy} sx={{ padding: { xs: 1, md: 5 } }}>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Epilogue",
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '#1D1E1B',
                                    lineHeight: "24px",
                                }}>
                                What We Do?
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    color: '#0275D8'
                                }}>
                                (Scope of work)
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Divider />
                        </Box>
                        {
                            props.loading ?
                                <Box>
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" height={50} width={'100%'} />
                                </Box>
                                :
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14.7px",
                                        lineHeight: "22.05px",
                                        color: "#605C78",
                                        whiteSpace: "pre-line",
                                    }}>
                                    {ReactHtmlParser(props.content[1])}
                                </Typography>
                        }

                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Divider />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                    <Stack spacing={4} onCopy={handleCopy} onCut={handleCopy} sx={{ padding: { xs: 1, md: 5 } }}>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Epilogue",
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '#1D1E1B',
                                    lineHeight: "24px",
                                }}>
                                Why We do?
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    color: '#0275D8'
                                }}>
                                (Vision)
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Divider />
                        </Box>
                        {
                            props.loading ?
                                <Box>
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" height={50} width={'100%'} />
                                </Box>
                                :
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14.7px",
                                        lineHeight: "22.05px",
                                        color: "#605C78",
                                        whiteSpace: "pre-line",
                                    }}>
                                    {ReactHtmlParser(props.content[2])}
                                </Typography>
                        }
                    </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                    {
                        props.loading ?
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                            :
                            <img
                                src={props.imageContent[2] ? props.imageContent[2] : '/v2/images/banner-placeholder.png'}
                                width={props.imageContent[2] ? '100%' : '60%'}
                                height={props.imageContent[2] ? '100%' : '100%'}
                                alt="Why We Do Image"
                                style={{ display: 'block', margin: 'auto' }}
                            />
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

function HowWeDoSection(props) {

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
            id='how_we_do'
            sx={{
                padding: 4,
                borderRadius: '5px',
            }}>
            <Grid container spacing={3}>
                <Grid item md={6} xs={12} sx={{display: {xs: "none", md: "block"}}}>
                    {
                        props.loading ?
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                            :
                            <img
                                src={props.imageContent[3] ? props.imageContent[3] : '/v2/images/banner-placeholder.png'}
                                width={props.imageContent[3] ? '100%' : '100%'}
                                height={props.imageContent[3] ? '100%' : '100%'}
                                alt="How We Do Image"
                                style={{ display: 'block', margin: 'auto' }}
                            />
                    }
                </Grid>
                <Grid item md={6} xs={12}>
                    <Stack spacing={4} onCopy={handleCopy} onCut={handleCopy} sx={{ padding: { xs: 1, md: 5 } }}>
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Epilogue",
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '#1D1E1B',
                                    lineHeight: "24px",
                                }}>
                                How we do?
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    color: '#0275D8'
                                }}>
                                (Operations and Culture & Environment)
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Divider />
                        </Box>

                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            {
                            props.loading ?
                                <Skeleton variant="rounded" height={300} width={'100%'} />
                                :
                                <img
                                    src={props.imageContent[3] ? props.imageContent[3] : '/v2/images/banner-placeholder.png'}
                                    width={props.imageContent[3] ? '100%' : '100%'}
                                    height={props.imageContent[3] ? '100%' : '100%'}
                                    alt="How We Do Image"
                                    style={{ display: 'block', margin: 'auto' }}
                                />
                            }
                        </Box>

                        {
                            props.loading ?
                                <Box>
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" width={'100%'} />
                                    <Skeleton variant="text" height={50} width={'100%'} />
                                </Box>
                                :
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14.7px",
                                        lineHeight: "22.05px",
                                        color: "#605C78",
                                        whiteSpace: "pre-line",
                                    }}>
                                    {ReactHtmlParser(props.content[3])}
                                </Typography>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    )
}

function OurCulture(props) {

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        <Paper
            id='our_culture'
            sx={{
                padding: 4,
                borderRadius: '5px',
            }}>
            <Typography
                variant="h5"
                onCopy={handleCopy}
                onCut={handleCopy}
                sx={{
                    fontWeight: 700,
                    fontSize: '18px'
                }}>
                Our Culture
            </Typography>
            <Box mt={2} />
            <Divider />
            <Box mt={2} />

            {
                props.loading ?
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item md={4}>
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                        </Grid>
                        <Grid item md={4}>
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                        </Grid>
                        <Grid item md={4}>
                            <Skeleton variant="rounded" height={300} width={'100%'} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} alignItems={'center'}>
                        {
                            props.images && props.images.slice(0, 6).map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item md={4}>
                                            <img src={item.s3_url} width="100%" height="100%" alt="" />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid>
            }
        </Paper>
    )
}

function ScrollableTabsButtonAuto(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                display: { xs: 'block', md: 'none' },
                maxWidth: { xs: '100%', sm: '100%' },
                bgcolor: 'background.paper',
                boxShadow: 1
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={true}
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
            >
                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='basic_details'
                >
                    <Tab label="Basic Details" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='about_box'
                >
                    <Tab label="Overview" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='meet_the_team'
                >
                    <Tab label="Meet The Team?" sx={{ textTransform: 'capitalize', minWidth: 200, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='what_we_do'
                >
                    <Tab label="What we do?" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='why_we_do'
                >
                    <Tab label="Why we do?" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='how_we_do'
                >
                    <Tab label="How we do?" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

                <Link
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to='our_culture'
                >
                    <Tab label="Our Culture" sx={{ textTransform: 'capitalize', minWidth: 120, paddingX: 2, color: 'black' }} />
                </Link>

            </Tabs>
        </Box>
    );
}


export default function SingleEmployerInformation(props) {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    const [imageContent, setImageContent] = useState([]);

    useEffect(() => {
        if (props?.employerInfo?.employer_contents) {
            let formattedContent = [];
            /* eslint-disable no-unused-expressions */
            props?.employerInfo?.employer_contents?.forEach((item) => {
                formattedContent[item.employer_content_section_id - 1] = item.description;
            });

            let formattedImageContent = [];
            props?.employerInfo?.employer_contents?.forEach((item) => {
                formattedImageContent[item.employer_content_section_id - 1] = item.s3_url;
            });


            // Set content with all descriptions
            setContent((prevContent) => [...prevContent, ...formattedContent]);
            setImageContent((prevImageContent) => [...prevImageContent, ...formattedImageContent]);
        }
    }, [props.employerInfo]);

    return (
        <React.Fragment>
            <Stack spacing={2} mt={4}>
                <React.Fragment>
                    <ProfileNavbar info={props?.employerInfo} content={content} jobList={false} />

                    <Box id="basic_details" sx={{display: {xs: "block", md: "none"}}}>
                        <CompanyInformationCard employerInfo={props?.employerInfo} />
                    </Box>

                    {/* Company Overview Section */}
                    {content[0] ?
                        <CompanyOverviewSection loading={loading} content={content} imageContent={imageContent} />
                        :
                        null
                    }

                    {/* Meet the Team Section */}
                    {props?.employerInfo && props?.employerInfo?.employer_leaders && props?.employerInfo?.employer_leaders?.length > 0 ?
                        <MeetTheTeamSection loading={loading} team={props?.employerInfo?.employer_leaders} />
                        :
                        null
                    }

                    {/* Organogram Section */}
                    {props.employerInfo && props.employerInfo?.organizational_structure_image_s3 ?
                        <OrganogramSection imageContent={props?.employerInfo?.organizational_structure_image_s3} />
                        :
                        null
                    }

                    {/* Mission Vision statement */}
                    {content[1] ? 
                        <MissionAndVisionSection loading={loading} content={content} imageContent={imageContent} />
                        :
                        null
                    }

                    {/* What We Do - Small Screen Only */}
                    {content[1] ?
                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <WhatWeDo loading={loading} content={content} imageContent={imageContent} />
                        </Box>
                        :
                        null
                    }

                    {/* Why We Do - Small Screen Only */}
                    {content[1] ? 
                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <WhyWeDo loading={loading} content={content} imageContent={imageContent} />
                        </Box>
                        :
                        null
                    }

                    {/* How We do Section */}
                    {content[3] ?
                        <HowWeDoSection loading={loading} content={content} imageContent={imageContent} />
                        :
                        null
                    }

                    {/* Our Culture */}
                    {props.employerInfo && props.employerInfo.employer_galleries && props.employerInfo.employer_galleries.length > 0 ?
                        <OurCulture loading={props.loading} images={props?.employerInfo?.employer_galleries} />
                        :
                        null
                    }

                </React.Fragment>
            </Stack>
        </React.Fragment>

    )
}