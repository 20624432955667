import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Footerv2 from "./Footerv2";
import Styles from "../../css/footer.module.css";
import CallIcon from "@mui/icons-material/Call";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function PlayStoreIcon() {
  return (
    <img
      src="/v2/images/Google_Play_Store.png"
      height={"50%"}
      alt="Play Store Icon"
      className="img-fluid"
    />
  );
}
function AppleStoreIcon() {
  return (
    <img
      src="/v2/images/appleStoreIcon.png"
      height={"50%"}
      alt="Apple Store Icon"
      className="img-fluid"
    />
  );
}

function LongFooter() {
  const { isLoggedin } = useContext(UserContext);
  const currentYear = new Date().getFullYear();

  const { t } = useTranslation("footer");
  const location = useLocation();
  const isLPPath = location.pathname.includes("lp");
  return (
    <div>
      {isLPPath ? (
        <section style={{ backgroundColor: "#131525" }}>
          <footer
            className="text-center text-lg-start"
            style={{ paddingTop: "70px", paddingBottom: "130px" }}
          >
            <div className="container">
              <Stack spacing={2}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    "@media (max-width: 600px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  © {currentYear} {t("copy-right-section.text-1")}{" "}
                  : atb-jobs.com, {t("copy-right-section.text-2")}
                </Typography>
                <Link to={"/terms-conditions"}>
                  <Typography
                    sx={{
                      color: "#0275D8",
                      fontSize: "18px",
                      "@media (max-width: 600px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {t("footer-section.menu-3.item-3")}
                  </Typography>
                </Link>
              </Stack>
            </div>
          </footer>
        </section>
      ) : (
        <div>
          <section style={{ backgroundColor: "#1F2131" }}>
            <Footerv2 />
          </section>

          {/* <!-- Section: Social media --> */}
          <section style={{ backgroundColor: "#131525" }}>
            <footer
              className="text-left text-lg-start"
              style={{ paddingTop: "70px" }}
            >
              <div className="container">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={3}>
                    <div>
                      <div className={Styles.footerItemsHeader}>
                        {t("footer-section.menu-1.title")}
                      </div>
                      <ul className={Styles.footerList}>
                        <li>
                          <Link
                            to={"/candidate-register"}
                            className="text-lightss"
                          >
                            {t("footer-section.menu-1.item-1")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/login"} className="text-lightss">
                            {t("footer-section.menu-1.item-2")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/search/jobs"} className="text-lightss">
                            {t("footer-section.menu-1.item-3")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/search/cxo-jobs",
                              state: { fromHeader: true },
                            }}
                            className="text-lightss"
                          >
                            {t("footer-section.menu-1.item-4")}
                          </Link>
                        </li>
                        <li>
                          <Typography
                            className="text-lightss"
                            sx={{
                              color: "white",
                            }}
                          >
                            {t("footer-section.menu-1.item-5")}
                          </Typography>
                        </li>
                        <li className="d-flex" style={{ gap: "10px" }}>
                          <li>
                            <Link
                              to={{
                                pathname:
                                  "https://play.google.com/store/apps/details?id=com.candidate.atb_jobs",
                              }}
                              target="_blank"
                            >
                              <PlayStoreIcon />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname:
                                  "https://apps.apple.com/us/app/atb-jobs/id6739555174",
                              }}
                              target="_blank"
                            >
                              <AppleStoreIcon />
                            </Link>
                          </li>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div>
                      <div className={Styles.footerItemsHeader}>
                        {t("footer-section.menu-2.title")}
                      </div>
                      <ul className={Styles.footerList}>
                        <li>
                          <Link className="text-lightss">
                            <span
                              onClick={() =>
                                (window.location.href =
                                  "https://employer.atb-jobs.com/auth/login")
                              }
                            >
                              {t("footer-section.menu-2.item-1")}
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link className="text-lightss">
                            {" "}
                            <span
                              onClick={() =>
                                (window.location.href =
                                  "https://employer.atb-jobs.com/auth/registration")
                              }
                            >
                              {t("footer-section.menu-2.item-2")}
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link className="text-lightss">
                            {" "}
                            <span
                              onClick={() =>
                                (window.location.href =
                                  "https://employer.atb-jobs.com/auth/login")
                              }
                            >
                              {t("footer-section.menu-2.item-3")}
                            </span>
                          </Link>
                        </li>
                        {/* <li>
                                                        <Link to={"/search/candidates"} className="text-lightss">{t("footer-section.menu-2.item-4")}</Link>
                                                    </li> */}
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div>
                      <div className={Styles.footerItemsHeader}>
                        {t("footer-section.menu-3.title")}
                      </div>
                      <ul className={Styles.footerList}>
                        <li>
                          <Link to={"/our-story"} className="text-lightss">
                            {t("footer-section.menu-3.item-1")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact-us"} className="text-lightss">
                            {t("footer-section.menu-3.item-2")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/terms-conditions"}
                            className="text-lightss"
                          >
                            {t("footer-section.menu-3.item-3")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/privacy-policy"} className="text-lightss">
                            {t("footer-section.menu-3.item-4")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/refund-policy"} className="text-lightss">
                            {t("footer-section.menu-3.item-5")}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/faq"} className="text-lightss">
                            {t("footer-section.menu-3.item-6")}
                          </Link>
                        </li>
                        <li>
                          <a
                            href="https://employer.atb-jobs.com/faq"
                            className="text-lightss"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("footer-section.menu-3.item-7")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={Styles.footerItemsHeader}>
                      {t("footer-section.menu-4.title")}
                    </div>
                    <ul className={Styles.contactList}>
                      <li>
                        <div className={Styles.iconContainer}>
                          <CallIcon className={Styles.icon} />
                        </div>
                        <div>{t("footer-section.menu-4.item-1")}</div>
                      </li>
                      <li>
                        <div className={Styles.iconContainer}>
                          <EmailOutlinedIcon className={Styles.icon} />
                        </div>
                        <div>{t("footer-section.menu-4.item-2")}</div>
                      </li>
                      <li>
                        <div className={Styles.iconContainer}>
                          <FmdGoodOutlinedIcon className={Styles.icon} />
                        </div>
                        <div>{t("footer-section.menu-4.item-3")}</div>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </div>
              <hr style={{ borderTop: "1px solid #3D3F4E" }} />
              <div className="container">
                <Grid
                  container
                  spacing={1}
                  sx={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item sx={{ color: "white" }}>
                    © {currentYear} {t("copy-right-section.text-1")} :{" "}
                    <a
                      className="fw-bold"
                      href="/"
                      style={{ color: "#0275D8" }}
                    >
                      atb-jobs.com
                    </a>
                    , {t("copy-right-section.text-2")}
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                      <Grid item sx={{ color: "#0275D8" }}>
                        {t("copy-right-section.text-3")} :
                      </Grid>
                      <Grid item>
                        <Link
                          to={{
                            pathname:
                              "https://www.facebook.com/atBJobsBangladesh",
                          }}
                          target="_blank"
                        >
                          <img
                            style={{ width: "28px" }}
                            src="/images/facebook.png"
                            alt=""
                          />
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          to={{
                            pathname:
                              "https://www.linkedin.com/company/atb-jobs-bangladesh",
                          }}
                          target="_blank"
                        >
                          <img
                            style={{ width: "30px" }}
                            src="/images/linkedin.png"
                            alt=""
                          />
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          to={{
                            pathname:
                              "https://www.instagram.com/atbjobsbangladesh/",
                          }}
                          target="_blank"
                        >
                          <img
                            style={{ width: "28px" }}
                            src="/images/instagram.png"
                            alt=""
                          />
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          to={{
                            pathname:
                              "https://www.tiktok.com/music/original-sound-atB-Jobs-7411056232603126544?is_from_webapp=1&sender_device=pc",
                          }}
                          target="_blank"
                        >
                          <img
                            style={{ width: "28px" }}
                            src="/images/tiktok_icon.png"
                            alt=""
                          />
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          to={{ pathname: "http://www.youtube.com/@atBJobs" }}
                          target="_blank"
                        >
                          <img
                            style={{ width: "28px" }}
                            src="/images/youtube_icon.png"
                            alt=""
                          />
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </footer>
          </section>
        </div>
      )}
    </div>
  );
}

export default LongFooter;
